export function removeItem(items, item) {
  let index = [...items].findIndex((_item) => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items;
}

export function replaceItem(items, item) {
  let index = [...items].findIndex((_item) => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1, item);
  }
  return items;
}

export function insertItem(items, item) {
  let index = [...items].findIndex((_item) => _item.id == item.id);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items;
}

export function parseParams(item) {
  for (const key in item) {
    if (item[key] === "true") {
      item[key] = true;
      continue;
    }

    if (item[key] === "false") {
      item[key] = false;
      continue;
    }

    if (/^[-]?[1-9][0-9]*[.]?[0-9]*$/.test(item[key])) {
      item[key] = Number(item[key]);
      continue;
    }
  }

  return item;
}

export default { removeItem, replaceItem, insertItem, parseParams };
